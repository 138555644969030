<template>
    <div class="container">


<v-btn class="btn-1">
    test
</v-btn>

FCM

{{messages}}
<br>   
   {{notifications}}

                <!-- Feed pagination -->
                <div class="text-center">
                  <v-pagination
                    v-model="pegination"
                    :length="meta.last_page"
                    :total-visible="7"
                  ></v-pagination>
                </div>

    </div>
</template>


<script>

import { mapGetters } from 'vuex'
import {  onMessage } from "firebase/messaging";


export default {
    middleware: ['auth'],

    data() {
        return {
            vendor: {},
            chats: [],
            loading: false,
            errorMsg: '',
            errored: null,
            messages: [],
            number: 0,
            pegination: 0,          
        }
    },
    async mounted(){
        await this.hookFcm();
       // this.getVendor();

        
    },
    computed:{
        ...mapGetters({
                user: 'auth/user',
                notifications: 'notification/getNotifications',
                meta: 'notification/getMeta',
            }),
                          
    }, 
    watch:{
        "pegination": "switchPage",
    },
    methods:{
            switchPage () {
            const params = {       
                "page": this.pegination ? this.pegination : this.$route.query.page
            };
            this.$store.dispatch('notification/fetchNotifications', params)

            },
            async hookFcm(){
                

                const messaging = this.$messaging;
                console.log("hookFcm");
                console.log(messaging);
                
                await this.$store.dispatch('notification/fetchNotifications');

                onMessage(messaging, (payload) => {
                    console.log('Message received. ', payload);
                    this.messages = this.messages.concat(payload.data);
                    let msg = {
                        data: payload.data
                    }
                    this.notifications.unshift(msg);

                    // ...
                });


            },


    }
}
</script>

<style scoped>

.btn-1 {


display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 12px 32px;

position: static;
width: 121px;
height: 46px;
left: 127px;
top: 0px;

/* Black */

background: #000000;
border-radius: 12px;

/* Inside Auto Layout */

flex: none;
order: 1;
flex-grow: 0;
margin: 0px 16px;

}
</style>